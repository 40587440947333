<template>
  <v-container fluid>
    <v-layout row>
      <v-flex xs1>
        <v-subheader><v-icon>mic</v-icon></v-subheader>
      </v-flex>
      <v-flex xs2>
        <v-subheader>Voice {{ document.status }}</v-subheader>
      </v-flex>
      <v-flex xs4>
        <v-subheader v-if="showEnrolled">
          <v-select
            :items="Object.keys(document.filesByDate)"
            item-value="guid"
            item-text="created"
            v-model="currentTimestamp"
            @input="changeDocument"
          />
      </v-subheader>
      </v-flex>
    </v-layout>
    <v-layout  v-if="showEnrolled" row>
      <v-flex xs1 />
      <v-flex xs2><v-subheader>Available Files</v-subheader></v-flex>
      <v-flex>
        <v-layout
        row
        v-for="(file, key) in document.filesByDate[this.currentTimestamp]"
        :key="key"
        >
          <v-flex>
            <v-subheader>
              <audio ref="players" type="audio/x-wav" controls>
                <source :src="generateUrl(file)" />
              </audio>
          </v-subheader>
          </v-flex>
        </v-layout>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import ues from '@/services/UserEnrollmentService';

export default {
  name: 'BaseVoiceCapture',
  data: () => ({
    displayFiles: [],
    currentTimestamp: '',
  }),
  props: {
    document: Object,
    accessToken: String,
  },
  components: {
    BaseControlInput: () => import('@/components/base/BaseControlInput'),
  },
  methods: {
    generateUrl(file) {
      if (this.accessToken) {
        return ues.getGuidSrc(file.guid, this.accessToken);
      }
      return '';
    },
    changeDocument(val) {
      this.displayFiles = this.document.filesByDate[val];
      this.$refs.players.forEach(player => player.load());
    },
  },
  computed: {
    showEnrolled() {
      return this.document.status === 'Enrolled';
    },
  },
  mounted() {
    if (this.document.hasFiles) {
      const displayDate = Object.keys(this.document.filesByDate)[0];
      this.displayFiles = this.document.filesByDate[displayDate];
      this.currentTimestamp = displayDate;
    }
  },
};
</script>
